<template>
  <div id="buy">
    <article id="wrap">
      <AppHeader theme="white">わたしのこと</AppHeader>

      <section class="contents pb_150">

        <div class="contentInner">

          <div class="ta_c txt_16 bold mb_10">発送済に変更する</div>

          <div class="mb_30">
            <label for="message">メッセージ</label>
            <textarea id="message" rows="5" cols="" v-model="message">

            </textarea>
            <div class="supplement">※メッセージは購入者の「わたしのこと」購入履歴に表示されます</div>
          </div>

          <div class="bt_form mb_30"><button @click="send">発送済に変更</button></div>

        </div><!--.contentInner-->

      </section>

      <AppFooter fixed></AppFooter>
    </article>
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
export default {
  components: { AppHeader, AppFooter },
  data () {
    return {
      message: ""
    }
  },
  methods: {
    async send () {
      await this.$http.put('orders/' + this.$route.params.id, {message: this.message});
      this.$router.push({name: 'orders'});
    }
  }
}
</script>
